<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col
        v-for="n in 6"
        :key="n"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
        <v-layout align-center>
        <v-img width="150" class="white--text " :src="require(`../assets/page${n}.png`)"></v-img>
        </v-layout>
        <v-card-subtitle v-text="title[n-1]"></v-card-subtitle>
        </v-card>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Version100",
  data: () => ({
      title: [
        '点击按钮添加清单，可选择象限，添加标签', 
        '左滑完成，右滑删除，可在回收站还原', 
        '左滑还原，右滑删除，彻底删除清单',
        '日历视图，列表罗列，清单日期标记',
        '清单展现灵活设置，并包含多主题，多语言',
        '搜索标题，标签，内容等，保留历史记录',
      ]
  }),
};
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>