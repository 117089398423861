import Vue from "vue";
import VueRouter from "vue-router";
import Todo from "../components/Todo.vue"
import Help from "../components/Help.vue"
import Iounce from "../components/Iounce.vue"
import Version100 from "../components/Version100.vue"
import Version105 from "../components/Version105.vue"
import Version108 from "../components/Version108.vue"
import Version1011 from "../components/Version1011.vue"
import Version1016 from "../components/Version1016.vue"
import Version1020 from "../components/Version1020.vue"
import Version1023 from "../components/Version1023.vue"
import Version1028 from "../components/Version1028.vue"
import Version1030 from "../components/Version1030.vue"
import Version111 from "../components/Version111.vue"
import Version1111 from "../components/Version1111.vue"
import Version1116 from "../components/Version1116.vue"
import Version1118 from "../components/Version1118.vue"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Todo,
        meta: {
            title: "一盎司清单"
        }
    },
    {
        path: "/version100",
        name: "version100",
        component: Version100,
        meta: {
            title: "一盎司清单V1.0.0主要功能"
        }
    },
    {
        path: "/version105",
        name: "version105",
        component: Version105,
        meta: {
            title: "一盎司清单V1.0.5主要更新"
        }
    },
    {
        path: "/version108",
        name: "version108",
        component: Version108,
        meta: {
            title: "一盎司清单V1.0.8主要更新"
        }
    },
    {
        path: "/version1011",
        name: "version1011",
        component: Version1011,
        meta: {
            title: "一盎司清单V1.0.11主要更新"
        }
    },
    {
        path: "/version1016",
        name: "version1016",
        component: Version1016,
        meta: {
            title: "一盎司清单V1.0.16主要更新"
        }
    },
    {
        path: "/version1020",
        name: "version1020",
        component: Version1020,
        meta: {
            title: "一盎司清单V1.0.20主要更新"
        }
    },
    {
        path: "/version1023",
        name: "version1023",
        component: Version1023,
        meta: {
            title: "一盎司清单V1.0.23主要更新"
        }
    },
    {
        path: "/version1028",
        name: "version1028",
        component: Version1028,
        meta: {
            title: "一盎司清单V1.0.28主要更新"
        }
    },
    {
        path: "/version1030",
        name: "version1030",
        component: Version1030,
        meta: {
            title: "一盎司清单V1.0.30主要更新"
        }
    },
    {
        path: "/version111",
        name: "version111",
        component: Version111,
        meta: {
            title: "一盎司清单V1.1.1主要更新"
        }
    },
    {
        path: "/version1111",
        name: "version1111",
        component: Version1111,
        meta: {
            title: "一盎司清单V1.1.11主要更新"
        }
    },
    {
        path: "/version1116",
        name: "version1116",
        component: Version1116,
        meta: {
            title: "一盎司清单V1.1.16主要更新"
        }
    },
    {
        path: "/version1118",
        name: "version1118",
        component: Version1118,
        meta: {
            title: "一盎司清单V1.1.18主要更新"
        }
    },
    {
        path: "/help",
        name: "help",
        component: Help,
        meta: {
            title: "帮助中心"
        }
    },
    {
        path: "/iounce",
        name: "iounce",
        component: Iounce,
        meta: {
            title: "一盎司科技"
        }
    },
];

const router = new VueRouter({
    routes
});

export default router;
