<template>
  <v-container>
    <div style="height: 16px" />
    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">关于我们</p>
    </v-row>

    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        武汉一盎司科技有限公司，致力于为客户提供定制化的应用软件开发服务、也乐于为广大用户提供优质的互联网产品。<br/>
        一盎司清单是公司正在推出的一款按四象限法则记录待办事项、合理安排计划的效率类软件。
      </p>
    </v-row>
    <div style="height: 64px" />

    <v-row class="text-left font-weight-bold">
      <div>
        <v-icon color="green darken-1">fab fa-weixin</v-icon>
      </div>
      <p style="color: #212121; font-size: 20px">&nbsp;&nbsp;微信公众号</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">一盎司科技</p>
    </v-row>
    <div style="height: 64px" />

    <v-row class="text-left font-weight-bold">
      <div>
        <v-icon color="red darken-1">fab fa-weibo</v-icon>
      </div>
      <p style="color: #212121; font-size: 20px">&nbsp;&nbsp;微博</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">一盎司科技</p>
    </v-row>
    <div style="height: 64px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">邮箱</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">contact@iounce.com</p>
    </v-row>
    <div style="height: 64px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">QQ群(一盎司清单App)</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">906772910</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Iounce",
};
</script>