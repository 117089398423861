import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: colors.indigo.accent2, // #E53935
        secondary: colors.indigo.accent3, // #FFCDD2
        accent: colors.deepOrange, // #3F51B5
      },
    },
  },
});
