<template>
  <v-container fluid class="grey lighten-5">
    <v-row>
      <v-col
        v-for="n in 2"
        :key="n"
      >

      <v-row 
      justify="center"
      align="center">
        <v-card
          width="278"
         
          class="pa-2"
          outlined
          tile
        >
        <v-layout align-center>
        <v-img width="150" class="white--text " :src="require(`../assets/105/page${n}.png`)"></v-img>
        </v-layout>
        <v-card-subtitle v-text="title[n-1]"></v-card-subtitle>
        </v-card>
      </v-row>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Version105",
  data: () => ({
      title: [
        '添加清单，点击返回按钮，可默认设置为自动保存', 
        '事件日历，可同时展示一个月周期内所有清单信息',
      ]
  }),
};
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>