var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-btn',{staticClass:"my-2",attrs:{"text":"","large":""},on:{"click":_vm.openTodo}},[_vm._v(" 一盎司清单 ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"text":"","large":""}},'v-btn',attrs,false),on),[_vm._v(" 功能介绍 ")])]}}])},[_c('v-list',_vm._l((_vm.versions),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.openVersion(item, i)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-btn',{staticClass:"my-2",attrs:{"text":"","large":""},on:{"click":_vm.openHelp}},[_vm._v(" 帮助中心 ")]),_c('v-btn',{staticClass:"my-2",attrs:{"text":"","large":""},on:{"click":_vm.openIounce}},[_vm._v(" 一盎司科技 ")])],1),_c('v-main',[_c('router-view')],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004539","target":"_blank"}},[_vm._v("鄂公网安备42011102004539号")]),_c('a',[_vm._v("    ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("鄂ICP备2021012277号-1")]),_c('a',{staticClass:"text-decoration-none"},[_vm._v("   ©"+_vm._s(new Date().getFullYear())+" iounce   ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://www.iounce.com/#/iounce","target":"_blank"}},[_vm._v("一盎司科技")]),_c('a',[_vm._v("    ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://www.iounce.com/protocol.html","target":"_blank"}},[_vm._v("服务协议")]),_c('a',[_vm._v("    ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://www.iounce.com/privacy.html","target":"_blank"}},[_vm._v("隐私政策")]),_c('a',[_vm._v("    ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-tooltip',{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(hover)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-1"},on:{"click":_vm.showWeixin}},'v-icon',attrs,false),on),[_vm._v("fab fa-weixin")]):_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.showWeixin}},'v-icon',attrs,false),on),[_vm._v("fab fa-weixin")])]}}],null,true)},[_c('v-img',{staticClass:"my-3",attrs:{"src":require('./assets/iounce.jpg'),"contain":"","height":"96"}})],1)]}}])}),_c('a',[_vm._v("    ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(hover)?_c('v-icon',{attrs:{"color":"red darken-1"},on:{"click":_vm.openWeibo}},[_vm._v("fab fa-weibo")]):_c('v-icon',{on:{"click":_vm.openWeibo}},[_vm._v("fab fa-weibo")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }