<template>
  <v-app>
    <v-app-bar app >
      <v-btn class="my-2" text large @click="openTodo"> 一盎司清单 </v-btn>

      <v-spacer></v-spacer>
      <v-menu bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="my-2" text large v-bind="attrs" v-on="on">
            功能介绍
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in versions"
            :key="i"
            @click="openVersion(item, i)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn class="my-2" text large @click="openHelp"> 帮助中心 </v-btn>
      <v-btn class="my-2" text large @click="openIounce"> 一盎司科技 </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer padless>
      <v-card-text>
        <v-row justify="center" no-gutters>
          <v-col class="text-center">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004539"
              target="_blank"
              class="text-decoration-none"
              >鄂公网安备42011102004539号</a
            >
            <a>&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="text-decoration-none"
              >鄂ICP备2021012277号-1</a
            >
            <a class="text-decoration-none">&nbsp;&nbsp;
            ©{{ new Date().getFullYear() }}
            iounce
            &nbsp;&nbsp;</a>
            <a
              href="https://www.iounce.com/#/iounce"
              target="_blank"
              class="text-decoration-none"
              >一盎司科技</a
            >
            <a>&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a
              href="https://www.iounce.com/protocol.html"
              target="_blank"
              class="text-decoration-none"
              >服务协议</a
            >
            <a>&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a
              href="https://www.iounce.com/privacy.html"
              target="_blank"
              class="text-decoration-none"
              >隐私政策</a
            >
            <a>&nbsp;&nbsp;&nbsp;&nbsp;</a>

            <v-hover v-slot:default="{ hover }">
              <v-tooltip top color="white">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="hover"
                    color="green darken-1"
                    @click="showWeixin"
                    >fab fa-weixin</v-icon
                  >
                  <v-icon v-bind="attrs" v-on="on" v-else @click="showWeixin"
                    >fab fa-weixin</v-icon
                  >
                </template>
                <v-img
                  :src="require('./assets/iounce.jpg')"
                  class="my-3"
                  contain
                  height="96"
                />
              </v-tooltip>
            </v-hover>

            <a>&nbsp;&nbsp;&nbsp;&nbsp;</a>

            <v-hover v-slot:default="{ hover }">
              <v-icon v-if="hover" color="red darken-1" @click="openWeibo"
                >fab fa-weibo</v-icon
              >
              <v-icon v-else @click="openWeibo">fab fa-weibo</v-icon>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    versions: [
      { title: "V1.1.18", router: "/version1118" },
      { title: "V1.1.16", router: "/version1116" },
      { title: "V1.1.11", router: "/version1111" },
      { title: "V1.1.1", router: "/version111" },
    ],
  }),

  methods: {
    openTodo() {
      this.$router.push("/");
    },
    // eslint-disable-next-line no-unused-vars
    openVersion(item, i) {
      this.$router.push(item.router);
    },
    openHelp() {
      this.$router.push("/help");
    },
    openIounce() {
      this.$router.push("/iounce");
    },

    showWeixin() {},
    openWeibo() {
      window.open("https://weibo.com/u/7627619416");
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-size: 11px;
}
a:link {
  color: #757575;
}
a:visited {
  color: #757575;
}
a:hover {
  color: #212121;
}
a:active {
  color: #757575;
}
</style>
