<template>
  <v-container>
    <div style="height: 16px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">不注册或者不登录能否使用？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        不注册或者不登录，可以正常使用，只是每天新建的清单数量有限制，同时无法同步到其他设备。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">新建清单时，如何选择象限？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        四象限法则是著名管理学家史蒂芬·科维提出的一个时间管理理论，把工作按照重要和紧急两个不同的程度进行了划分：<br />
        第一象限：重要而且紧急，立即去做[20%~25%]（如项目的谈判，重要的会议等）<br />
        第二象限：重要但不紧急，有计划的做[65%~80%]（如个人技能提升，职业规划等）<br />
        第三象限：不重要但紧急，交给别人去做[15%]（如接一般电话，开一般的会等）<br />
        第四象限：不重要也不紧急，尽量别做[5%]（如闲聊，消遣，看娱乐视频等）<br />
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">
        四象限各代表的图标和颜色能否设置？
      </p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        四象限各代表的图标和颜色暂时不能设置（后续可考虑）<br />
      </p>
    </v-row>
    <v-row>
      <div>
        <v-btn large
          ><div style="font-size: 16px">第一象限</div>
          <v-icon large color="red" right>mdi-star</v-icon>
        </v-btn>
        <v-btn large
          ><div style="font-size: 16px">第二象限</div>
          <v-icon large color="light-green accent-4" right>mdi-star</v-icon>
        </v-btn>
        <v-btn large
          ><div style="font-size: 16px">第三象限</div>
          <v-icon large color="amber darken-3" right>mdi-star</v-icon>
        </v-btn>
        <v-btn large
          ><div style="font-size: 16px">第四象限</div>
          <v-icon large color="brown" right>mdi-star</v-icon>
        </v-btn>
      </div>
    </v-row>
    <div style="height: 24px" />

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">清单不提醒如何处理？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        以安卓手机为例，请检查系统权限设置，是否运行“一盎司清单”app自启动运行，是否已经获得通知权限。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">
        清单不震动或没有响铃如何处理？
      </p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        以安卓手机为例，请检查系统权限设置，是否已经获得震动权限和发声权限。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">清单如何设置为已完成？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        在主界面选中具体的清单，左滑拖动，点击“完成”即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何删除清单？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        在主界面选中具体的清单，右滑拖动，点击“删除”，此时清单会进入回收站；如果要彻底删除，可以清空回收站。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何查看已完成清单？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击设置菜单，滑动设置显示已完成菜单即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何签到？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击账户下拉框，打开账户中心页面，然后点击头像右侧的日历图标即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何修改密码？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击账户下拉框，打开账户中心页面，然后点击修改密码即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何修改头像？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击账户下拉框，点击修改头像菜单即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何注销账户？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击账户下拉框，打开账户中心页面，点击注销账户菜单即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何设置深色主题？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击设置菜单，滑动设置为深色模式即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何设置语言？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        打开抽屉菜单，然后点击设置菜单，点击多语言，选择语言，然后点击保存即可。
      </p>
    </v-row>

    <div style="height: 48px" />

    <v-row class="text-left font-weight-bold">
      <p style="color: #212121; font-size: 20px">如何联系我们？</p>
    </v-row>
    <v-row class="text-left">
      <p style="color: #212121; font-size: 18px">
        可以在主界面点击反馈菜单提交反馈意见，加入QQ群(906772910)，或者发送邮件到contact@iounce.com，也可以关注微信公众号和微博，搜索“一盎司科技”。
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Help",
};
</script>