<template>
  <v-container fluid class="grey lighten-5">
    <v-row>
      <v-col
        v-for="n in 3"
        :key="n"
      >

      <v-row 
      justify="center"
      align="center">
        <v-card
          width="278"
         
          class="pa-2"
          outlined
          tile
        >
        <v-layout align-center>
        <v-img width="150" class="white--text " :src="require(`../assets/1111/page${n}.png`)"></v-img>
        </v-layout>
        <v-card-subtitle v-text="title[n-1]"></v-card-subtitle>
        </v-card>
      </v-row>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Version1111",
  data: () => ({
      title: [
        '增加多字体设置，新增站酷快乐体、演示佛系体和杨任东竹石体等',
        '主界面页签新增生日、纪念日、倒数日和还款等，支持多个类别页签切换', 
        '添加界面新增生日、纪念日、倒数日和还款等，支持不同类别添加',
      ]
  }),
};
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>