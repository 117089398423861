<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="80"
        />
        <p style="color: #212121; font-size: 20px">一盎司清单</p>
        <p style="color: #757575; font-size: 18px">
          <i>一款按四象限法则记录待办事项、合理安排计划的效率类应用</i>
        </p>
      </v-col>
    </v-row>
    <v-row class="text-center" align="center" justify="center" no-gutters>
      <v-col lg="2">
        <v-btn color="grey lighten-5" large href="https://a.app.qq.com/o/simple.jsp?pkgname=com.iounce.todo" target="_blank">
          <v-icon x-large color="green">mdi-android</v-icon>
          Android
        </v-btn>

        <p style="color:grey; font-size:13px">已更新至V1.1.18</p>
      </v-col>
      <v-col lg="2">
        <v-btn color="grey lighten-5" large>
          <v-icon x-large color="blue">mdi-microsoft-windows</v-icon>
          Windows
        </v-btn>
        <p style="color:grey; font-size:13px">敬请期待</p>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div style="height: 32px" />
    <v-row class="flex d-flex">
      <v-col cols="12" sm="3">
        <v-img width="360" src="../assets/slide1.png"></v-img>
      </v-col>
      <v-col cols="12" sm="3">
        <v-img width="360" src="../assets/slide2.png"></v-img>
      </v-col>
      <v-col cols="12" sm="3">
        <v-img width="360" src="../assets/slide3.png"></v-img>
      </v-col>
      <v-col cols="12" sm="3">
        <v-img width="360" src="../assets/slide4.png"></v-img>
      </v-col>
    </v-row>
    <div style="height: 32px" />
    <v-divider></v-divider>
    <div style="height: 48px" />

    <v-row class="text-center">
      <v-col cols="12">
        <div class="bounce" style="color: #ff5722">
          <p class="letter">Try</p>
          <p class="letter">&nbsp;to</p>
          <p class="letter">&nbsp;do</p>
          <p class="letter">&nbsp;better</p>
          <p class="letter">.</p>
        </div>
      </v-col>
    </v-row>

    <v-timeline :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item color="blue accent-2" fill-dot right small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="blue accent-2">
              <v-icon dark size="42" class="mr-4">
                fas fa-calendar-check
              </v-icon>
              <h2 class="display-1 white--text font-weight-light">积少成多</h2>
            </v-card-title>
            <v-container>
              <v-row class="text-h6">
                <v-col cols="12" class="d-flex flex-column align-end">
                  一盎司的分量<br />
                  积少成多<br />
                  一盎司的清单<br />
                  高效生活
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>

      <v-timeline-item color="grey darken-3" fill-dot left small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="grey darken-3 justify-end">
              <h2 class="display-1 mr-4 white--text font-weight-light">
                所见所得
              </h2>
              <v-icon dark size="42">fas fa-crosshairs</v-icon>
            </v-card-title>
            <v-container>
              <v-row class="text-h6">
                <v-col cols="12" md="10">
                  简洁直观<br />
                  所见即所得<br />
                  轻重缓急<br />
                  四象限分隔
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>

      <v-timeline-item color="deep-orange darken-1" fill-dot right small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="deep-orange darken-1">
              <v-icon class="mr-4" dark size="42"> fab fa-gratipay </v-icon>
              <h2 class="display-1 white--text font-weight-light">蓬勃朝气</h2>
            </v-card-title>
            <v-container>
              <v-container>
                <v-row class="text-h6">
                  <v-col cols="12" class="d-flex flex-column align-end">
                    动效丰富<br />
                    平添几分活力<br />
                    主题切换<br />
                    勾勒几丝写意
                  </v-col>
                </v-row>
              </v-container>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>

      <v-timeline-item color="deep-purple accent-2" fill-dot left small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="deep-purple accent-2 justify-end">
              <h2 class="display-1 mr-4 white--text font-weight-light">
                成竹在胸
              </h2>
              <v-icon dark size="42"> mdi-alarm </v-icon>
            </v-card-title>
            <v-container>
              <v-row class="text-h6">
                <v-col cols="12" md="10">
                  震动与铃声<br />
                  不经意的提醒<br />
                  掌控时间<br />
                  坦然而不躁动于心
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>

      <v-timeline-item color="teal darken-1" fill-dot right small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="teal darken-1">
              <v-icon class="mr-4" dark size="42"> fas fa-award </v-icon>
              <h2 class="display-1 white--text font-weight-light">知行合一</h2>
            </v-card-title>
            <v-container>
              <v-row class="text-h6">
                <v-col cols="12" class="d-flex flex-column align-end">
                  预则立<br />
                  持之以恒<br />
                  知行合一<br />
                  水到渠成
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>

      <v-timeline-item color="lime lighten-1" fill-dot left small>
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 16 : 2">
            <v-card-title class="lime lighten-1">
              <v-icon class="mr-4" dark size="42"> fas fa-road </v-icon>
              <h2 class="display-1 white--text font-weight-light">只如初见</h2>
            </v-card-title>
            <v-container>
              <v-row class="text-h6">
                <v-col cols="12" md="10">
                  人生若只如初见<br />
                  回眸一笑<br />
                  如果恰好你也喜欢<br />
                  如此便好<br />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  name: "Todo",
};
</script>
  
<style lang="scss" scoped>
p {
  letter-spacing: 3px;
}

.bounce {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  height: 100%;
  font: normal bold 2rem "Product Sans", sans-serif;
  white-space: nowrap;
}

.letter {
  animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  margin-top: 0.5em;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 0.05em;
  font: normal 500 2rem "Varela Round", sans-serif;
}
.letter:nth-child(1) {
  animation-delay: 0s;
}
.letter:nth-child(2) {
  animation-delay: 0.0833333333s;
}
.letter:nth-child(3) {
  animation-delay: 0.1666666667s;
}
.letter:nth-child(4) {
  animation-delay: 0.25s;
}
.letter:nth-child(5) {
  animation-delay: 0.3333333333s;
}
.letter:nth-child(6) {
  animation-delay: 0.4166666667s;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 0.05em;
  }
  100% {
    transform: translate3d(0, -1em, 0);
    text-shadow: rgba(255, 255, 255, 0.4) 0 1em 0.35em;
  }
}
</style>