<template>
  <v-container fluid class="grey lighten-5">
    <v-row>
      <v-col
        v-for="n in 2"
        :key="n"
      >

      <v-row 
      justify="center"
      align="center">
        <v-card
          width="278"
         
          class="pa-2"
          outlined
          tile
        >
        <v-layout align-center>
        <v-img width="150" class="white--text " :src="require(`../assets/1020/page${n}.png`)"></v-img>
        </v-layout>
        <v-card-subtitle v-text="title[n-1]"></v-card-subtitle>
        </v-card>
      </v-row>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Version1020",
  data: () => ({
      title: [
        '添加界面，增加重复选择对话框，可自定义重复模式', 
        '日历等界面，清单添加右滑复制功能，可简化添加过程',
      ]
  }),
};
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>